import gsap from "gsap"
import { loader } from "library/Loader"
import { usePreloader } from "library/Loader/PreloaderUtils"
import { fresponsive } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useRef, useState } from "react"
import styled, { css } from "styled-components"
import { staticWhiteBackground } from "styles/colors"
import textStyles from "styles/text"
import LottieLogo from "./LottieLogo"

export default function Preloader() {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const innerWrapperRef = useRef<HTMLDivElement>(null)
	const logoWrapperRef = useRef<HTMLDivElement>(null)
	const [showElement, setShowElement] = useState(false)

	useAnimation(() => {
		if (!showElement) return

		gsap.to(logoWrapperRef.current, {
			opacity: 1,
			scale: 1,
			duration: 0.4,
			ease: "power2.inOut",
		})
	}, [showElement])

	const shrinkDown = () => {
		const tl = gsap.timeline({})
		tl.to(
			innerWrapperRef.current,
			{
				scale: 0,
				opacity: 0,
				duration: 1,

				ease: "power2.inOut",
			},
			0.5,
		)
		tl.to(
			logoWrapperRef.current,
			{
				scale: 0,
				opacity: 0,
				duration: 0.5,
				ease: "power1.inOut",
			},
			1,
		)
	}

	usePreloader({
		only: "whenAtTop",
		duration: 1.5,
		callback: shrinkDown,
	})

	/**
	 * you'll probably want to use a shorter animation when the page is scrolled,
	 * since that means the user is probably reloading the page (which we want to be fast)
	 */
	usePreloader({
		only: "whenScrolled",
		duration: 1.5,
		callback: shrinkDown,
	})

	loader.useEventListener("end", () =>
		gsap.set(wrapperRef.current, { display: "none" }),
	)

	return (
		<Wrapper ref={wrapperRef}>
			<LogoWrapper ref={logoWrapperRef}>
				<LottieLogo setShowElement={setShowElement} />
			</LogoWrapper>
			<InnerWrapper ref={innerWrapperRef} />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: clip;
	background: transparent;
	z-index: 100;
	pointer-events: none;
	display: grid;
	place-items: center;
	${textStyles.h1}
`

const LogoWrapper = styled.div`
	display: grid;
	place-items: center;
	z-index: 3;
	opacity: 0;
	scale: 0.5;
	border-radius: 99vw;

	${fresponsive(css`
		width: 100px;
		height: 100px;
		border: 10px solid #dfe0e9;
		padding: 10px;
	`)}
`

const InnerWrapper = styled.div`
	position: absolute;
	${staticWhiteBackground};
	z-index: 1;
	scale: 1;
	display: grid;
	place-items: center;
	width: 200vmax;
	height: 200vmax;
	border-radius: 200vmax;
`
