import gsap from "gsap"
import { loader } from "library/Loader"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useAutoHideHeader from "library/useAutoHideHeader"
import useMedia from "library/useMedia"
import { useCallback, useRef, useState } from "react"
import styled, { css } from "styled-components"
import links from "utils/links"
import { PrimaryButton } from "../Buttons/Button"
import { LinkText } from "../Buttons/LinkText"
import { LoginWrapper } from "./LoginWrapper"
import TabletMobileDropDown from "./TabletMobileDropdown"

// Todo: Add dropdowns as new pages are ready to release

export default function Header() {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const responsive = useMedia(false, false, true, true)
	const mobile = useMedia(false, false, false, true)
	const [initialAnimationComplete, setInitialAnimationComplete] =
		useState(false)
	useAutoHideHeader(initialAnimationComplete ? wrapperRef : null)

	const loadAnimation = useCallback(() => {
		gsap.to(wrapperRef.current, {
			y: 0,
			duration: 1.5,
			ease: "power3.out",
			delay: 0.5,
			onComplete: () => setInitialAnimationComplete(true),
		})
	}, [])

	loader.useEventListener("end", loadAnimation)

	return (
		<Wrapper ref={wrapperRef}>
			{responsive && (
				<Inner>
					{!mobile && (
						<PrimaryButton to={links.becomeAPartner} color="black">
							Become a Partner
						</PrimaryButton>
					)}
					<TabletMobileDropDown scopeRef={wrapperRef} />
					{!mobile && (
						<PrimaryButton
							className="see-rotate-responsive"
							to={links.bookDemo}
							color="orange"
						>
							See Rotate
						</PrimaryButton>
					)}
				</Inner>
			)}
			{!responsive && (
				<Inner>
					<>
						<LoginWrapper />
						<Section>
							{/* <Dropdown hasOverview>Platform</Dropdown>
						<Dropdown>Solutions</Dropdown>
						<Dropdown>Partners</Dropdown> */}
							{/* <Dropdown>Company</Dropdown> */}
						</Section>
						<Section>
							<LinkText to={links.becomeAPartner}>Partner With Us</LinkText>
							<PrimaryButton to={links.bookDemo} color="orange">
								See Rotate
							</PrimaryButton>
						</Section>
					</>
				</Inner>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.header`
	position: fixed;
	display: grid;
	width: 100%;
	z-index: 5;
	color: white;
	transform: translateY(-100%);

	${fresponsive(css`
		padding: 36px 96px;
		top: 0;
	`)}

	${ftablet(css`
		padding: 36px 55px;
	`)}

	${fmobile(css`
		padding: 24px 0;
	`)}
`

const Inner = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;

	${ftablet(css`
		align-items: center;
	`)}

	${fmobile(css`
		justify-content: center;
	`)}
`

const Section = styled.div`
	display: flex;
	align-items: center;

	${fresponsive(css`
		gap: 24px;
	`)}
`
