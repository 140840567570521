import * as Popover from "@radix-ui/react-popover"
import { PrimaryButton } from "components/Buttons/Button"
import { LinkText } from "components/Buttons/LinkText"
import { ReactComponent as LogoSVG } from "images/global/logo.svg"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import getMedia from "library/getMedia"
import useAnimation from "library/useAnimation"
import { useBetterThrottle } from "library/useBetterThrottle"
import { getResponsivePixels } from "library/viewportUtils"
import { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import links from "utils/links"
import { LoginWrapper } from "./LoginWrapper"

const duration = 300

interface Data {
	title: string
	previewText: string
	iconName: string
	link: string
}

const data: Data[] = [
	{
		title: "Identity Hub",
		previewText: "Lorem ipsum odor amet, consectetuer adipiscing elit.",
		iconName: "email",
		link: "/",
	},
	{
		title: "Email Hub",
		previewText: "Lorem ipsum odor amet, consectetuer adipiscing elit.",
		iconName: "email",
		link: "/",
	},
	{
		title: "Endpoint Hub",
		previewText: "Lorem ipsum odor amet, consectetuer adipiscing elit.",
		iconName: "email",
		link: "/",
	},
	{
		title: "Training Hub",
		previewText: "Lorem ipsum odor amet, consectetuer adipiscing elit.",
		iconName: "email",
		link: "/",
	},
	{
		title: "EASM Hub",
		previewText: "Lorem ipsum odor amet, consectetuer adipiscing elit.",
		iconName: "email",
		link: "/",
	},
	{
		title: "Detection & Response",
		previewText: "Lorem ipsum odor amet, consectetuer adipiscing elit.",
		iconName: "email",
		link: "/",
	},
]

export default function TabletMobileDropDown({
	hasOverview,
	scopeRef,
}: {
	hasOverview?: boolean
	scopeRef: React.RefObject<HTMLDivElement>
}) {
	const [rawOpen, setOpen] = useState(false)
	const open = useBetterThrottle(rawOpen, duration)
	const lineRef1 = useRef<SVGLineElement | null>(null)
	const lineRef2 = useRef<SVGLineElement | null>(null)
	const lineRef3 = useRef<SVGLineElement | null>(null)
	const expandedRef = useRef<HTMLDivElement | null>(null)
	const openButtons = useRef<HTMLDivElement | null>(null)

	const timeline = useAnimation(
		() => {
			const tl = gsap.timeline({
				paused: true,
				duration: 0.3,
			})

			tl.fromTo(
				lineRef1.current,
				{
					attr: {
						x1: 0,
						x2: 36,
						y1: 0.5,
						y2: 0.5,
					},
				},
				{
					attr: {
						x1: 4,
						x2: 32,
						y1: -8,
						y2: 17,
					},
				},
				0,
			)

			tl.fromTo(
				lineRef2.current,
				{
					attr: {
						x1: 0,
						x2: 36,
						y1: 4.5,
						y2: 4.5,
					},
				},
				{
					attr: {
						x1: 4,
						x2: 32,
						y1: -8,
						y2: 17,
					},
				},
				0,
			)

			tl.fromTo(
				lineRef3.current,
				{
					attr: {
						x1: 0,
						x2: 36,
						y1: 8.5,
						y2: 8.5,
					},
				},
				{
					attr: {
						x1: 4,
						x2: 32,
						y1: 17,
						y2: -8,
					},
				},
				0,
			)
			tl.fromTo(
				expandedRef.current,
				{
					width: getResponsivePixels(getMedia(244, 244, 244, 350)),
				},
				{
					width: getResponsivePixels(getMedia(556, 556, 556, 335)),
					ease: "power2.inOut",
				},
				0,
			)
				.fromTo(
					".login-wrapper",
					{
						width: getResponsivePixels(getMedia(244, 244, 244, 350)),
					},
					{
						width: getResponsivePixels(getMedia(244, 556, 556, 335)),
						ease: "power2.inOut",
					},
					0,
				)
				.fromTo(
					openButtons.current,
					{
						width: getResponsivePixels(0),
						opacity: 0,
					},
					{
						width: getResponsivePixels(getMedia(220, 220, 220, 180)),
						opacity: 1,
						ease: "power2.inOut",
					},
					0,
				)
				.fromTo(
					".see-rotate-responsive",
					{
						opacity: 1,
						pointerEvents: "auto",
					},
					{
						opacity: 0,
						pointerEvents: "none",
						duration: 0.1,
					},
					0,
				)
				.fromTo(
					".mobile-logo",
					{
						opacity: 1,
						display: "inline-block",
						pointerEvents: "auto",
					},
					{
						opacity: 0,
						pointerEvents: "none",
						display: "none",
						duration: 0.1,
					},
					0,
				)

			return tl
		},
		[],
		{ scope: scopeRef, recreateOnResize: true },
	)

	useEffect(() => {
		if (!timeline) return

		if (open) {
			timeline.play()
		} else {
			timeline.reverse()
		}
	}, [timeline, open])

	return (
		<PopoverRoot open={open} onOpenChange={setOpen}>
			<ExpandedMenu ref={expandedRef}>
				<LoginWrapper>
					<LogoWrapper className="mobile-logo" to={links.home}>
						<LogoSVG />
					</LogoWrapper>
					<Children>
						<OpenButtons ref={openButtons}>
							<InnerDiv>
								<LinkText to={links.login} large>
									Login
								</LinkText>
								<PrimaryButton to={links.contactUs} color="orange">
									See Rotate
								</PrimaryButton>
							</InnerDiv>
						</OpenButtons>
						<MenuButton>
							<Lines
								viewBox="0 0 36 9"
								overflow="visible"
								width="100%"
								height="100%"
								preserveAspectRatio="none"
							>
								<Line
									ref={lineRef1}
									x1={0}
									x2={36}
									y1={0.5}
									y2={0.5}
									preserveAspectRatio="none"
								/>
								<Line
									ref={lineRef2}
									x1={0}
									x2={36}
									y1={4.5}
									y2={4.5}
									preserveAspectRatio="none"
								/>
								<Line
									ref={lineRef3}
									x1={0}
									x2={36}
									y1={8.5}
									y2={8.5}
									preserveAspectRatio="none"
								/>
							</Lines>
						</MenuButton>
					</Children>
				</LoginWrapper>
				{/*
    Todo: add the columns here once page  templates are complete. Same style as desktop but just stacked. 
*/}
			</ExpandedMenu>
		</PopoverRoot>
	)
}

const MenuButton = styled(Popover.Trigger)`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	-webkit-tap-highlight-color: transparent;

	${fresponsive(css`
		height: 9px;
		width: 36px;
	`)};
`

const Lines = styled.svg`
	${fresponsive(css`
		width: 100%;
		height: 100%;
	`)}
`

const Line = styled.line`
	stroke: ${colors.blueWhite};
	stroke-width: 1px;
	stroke-linecap: round;
`

const PopoverRoot = styled(Popover.Root)`
	width: 100%;
`

const ExpandedMenu = styled.div``

const OpenButtons = styled.div`
	position: relative;
	width: 0;
	overflow: clip;
	${fresponsive(css`
		height: 40px;
	`)};

	${ftablet(css`
		height: 47px;
	`)};
`

const InnerDiv = styled.div`
	position: absolute;
	display: flex;
	justify-content: space-between;
	object-position: center center;
	align-items: center;

	${fresponsive(css`
		position: relative;
		width: 180px;
		gap: 10px;
		height: 40px;
	`)};

	${ftablet(css`
		width: 220px;
		height: 47px;
	`)};
`

const Children = styled.div`
	display: flex;
	gap: 40px;
	align-items: center;
	justify-content: space-between;

	${fmobile(css`
		width: 100%;
	`)};
`

const LogoWrapper = styled(UniversalLink)`
	position: relative;
	font-family: none;
	display: none;

	${fmobile(css`
		display: inline-block;
		width: 96px;
	`)}
`
