import gsap from "gsap"
import { usePageTransition } from "library/Loader/TransitionUtils"
import UniversalImage from "library/UniversalImage"
import { fresponsive } from "library/fullyResponsive"
import { useRef } from "react"
import styled, { css } from "styled-components"
import { staticWhiteBackground } from "styles/colors"
import textStyles from "styles/text"
import LottieLogo from "./LottieLogo"

export default function Transition() {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const innerWrapperRef = useRef<HTMLDivElement>(null)
	const logoWrapperRef = useRef<HTMLDivElement>(null)

	const growIn = () => {
		const tl = gsap.timeline()

		tl.to(
			wrapperRef.current,
			{
				opacity: 1,
				duration: 1,
				ease: "power2.inOut",
			},
			0,
		).to(
			logoWrapperRef.current,
			{
				scale: 1,
				opacity: 1,
				duration: 0.5,
				ease: "power1.inOut",
			},
			0.5,
		)
	}

	const shrinkOut = () => {
		const tl = gsap.timeline()

		tl.to(
			innerWrapperRef.current,
			{
				scale: 0,
				opacity: 0,
				duration: 1,
				ease: "power2.inOut",
			},
			0.5,
		)
		tl.to(
			logoWrapperRef.current,
			{
				scale: 0,
				opacity: 0,
				duration: 0.5,
				ease: "power1.inOut",
			},
			1,
		)
	}

	usePageTransition("shrink-grow", {
		in: growIn,
		out: shrinkOut,
		inDuration: 1,
		outDuration: 1.5,
	})

	return (
		<Wrapper ref={wrapperRef}>
			<LogoWrapper ref={logoWrapperRef}>
				<LottieLogo />
			</LogoWrapper>
			<InnerWrapper ref={innerWrapperRef} />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	overflow: clip;
	background: transparent;
	z-index: 100;
	pointer-events: none;
	display: grid;
	place-items: center;
	${textStyles.h1}
`

const LogoWrapper = styled.div`
	display: grid;
	place-items: center;
	z-index: 3;
	opacity: 0;
	scale: 0.5;
	border-radius: 99vw;

	${fresponsive(css`
		width: 100px;
		height: 100px;
		border: 10px solid #dfe0e9;
		padding: 10px;
	`)}
`

const LogoPNG = styled(UniversalImage)`
	${fresponsive(css`
		width: 88px;
		height: 88px;
	`)}
`

const InnerWrapper = styled.div`
	position: absolute;
	${staticWhiteBackground};
	z-index: 1;
	scale: 1;
	display: grid;
	place-items: center;

	/* We need this to always be a circle and to always be larger than the screen */
	width: 200vmax;
	height: 200vmax;
	border-radius: 200vmax;
`

const Logo = styled.img``
