import { graphql, useStaticQuery } from "gatsby"
import { ReactComponent as LogoSVG } from "images/global/fullLogo.svg"
import { ReactComponent as LinkedInSVG } from "images/global/linkedin.svg"
import UniversalLink from "library/Loader/UniversalLink"
import { ScreenContext } from "library/ScreenContext"
import UniversalImage from "library/UniversalImage"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useMedia from "library/useMedia"
import { useContext } from "react"
import styled, { css } from "styled-components"
import { staticBackground } from "styles/colors"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"
import { PrimaryButton } from "./Buttons/Button"
import { LinkText } from "./Buttons/LinkText"

const footerData = [
	// {
	// 	section: "Platform",
	// 	links: [
	// 		{ name: "Platform Overview", to: links.platformOverview, disabled: true },
	// 		{ name: "Identity Hub", to: links.identityHub, disabled: true },
	// 		{ name: "Email Hub", to: links.emailHub, disabled: true },
	// 		{ name: "Endpoint Hub", to: links.endpointHub, disabled: true },
	// 		{ name: "Training Hub", to: links.trainingHub, disabled: true },
	// 		{ name: "EASM Hub", to: links.monitoringHub, disabled: true },
	// 		{
	// 			name: "Detection & Response",
	// 			to: links.detectionAndResponse,
	// 			disabled: true,
	// 		},
	// 	],
	// },
	// {
	// 	section: "Partners",
	// 	links: [
	// 		{ name: "Security Partners", to: links.securityPartners, disabled: true },
	// 		{
	// 			name: "Insurance Partners",
	// 			to: links.insurancePartners,
	// 			disabled: true,
	// 		},
	// 		{ name: "Become a Partner", to: links.becomeAPartner, disabled: true },
	// 	],
	// },
	// {
	// 	section: "Solutions",
	// 	links: [
	// 		{ name: "Insurance", to: links.insurance, disabled: true },
	// 		{ name: "Compliance", to: links.compliance, disabled: true },
	// 		{ name: "Incident Response", to: links.incidentResponse, disabled: true },
	// 		{ name: "24/7 MDR", to: links.mdr, disabled: true },
	// 	],
	// },
	{
		section: "Company",
		links: [
			// { name: "About Us", to: links.about, disabled: true },
			// { name: "Blog", to: links.blog, disabled: true },
			// { name: "Careers", to: links.careers, disabled: true },
			// { name: "Resource Center", to: links.resourceCenter, disabled: true },
			{ name: "Contact Us", to: links.contactUs, disabled: false },
		],
	},
	{
		section: "Legal",
		links: [
			{
				name: "Terms Of Use",
				to: links.termsOfUse,
				disabled: false,
			},
			{ name: "Privacy Policy", to: links.privacyPolicy, disabled: false },
		],
	},
]

export default function Footer() {
	const responsive = useMedia(false, false, true, true)
	const { mobile } = useContext(ScreenContext)

	const images: Queries.FooterQuery = useStaticQuery(graphql`
		query Footer {
			soc2: file(relativePath: { eq: "global/soc2.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
			footerUI: file(relativePath: { eq: "global/footerUI.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}

			footerTablet: file(relativePath: { eq: "global/footerUITablet.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
			footerTopMobile: file(relativePath: { eq: "global/mobileUITop.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
			footerBottomMobile: file(
				relativePath: { eq: "global/mobileUIBottom.png" }
			) {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`)

	return (
		<Wrapper data-header-hide>
			{mobile && (
				<Image
					image={images.soc2?.childImageSharp?.gatsbyImageData}
					alt="soc2"
				/>
			)}
			<Content>
				<Top>
					<Kicker>
						<UniversalLink to={links.home}>
							<Logo />
						</UniversalLink>
						<Text>Copyright Rotate 2024. All Rights Reserved.</Text>
						<Social to={links.linkedIn} ariaLabel="LinkedIn">
							<LinkedIn />
						</Social>
						{mobile && (
							<Buttons>
								<PrimaryButton color="orange" to={links.bookDemo}>
									See Rotate
								</PrimaryButton>
								<PrimaryButton color="black" to={links.login}>
									Login
								</PrimaryButton>
							</Buttons>
						)}
					</Kicker>
					<Links>
						{footerData.map((data, index) => (
							<Column key={data.section}>
								<SectionTitle>{data.section}</SectionTitle>
								{data.links.map((link, linkIndex) => (
									<LinkText
										key={link.name}
										to={link.to}
										aria-disabled={link.disabled}
										disabled={link.disabled}
									>
										{link.name}
									</LinkText>
								))}
							</Column>
						))}
					</Links>
				</Top>
				<Bottom>
					<Image
						image={images.soc2?.childImageSharp?.gatsbyImageData}
						alt="soc2"
					/>

					{!responsive && <SubTitle>Work Secured.</SubTitle>}

					<Buttons>
						{responsive && <SubTitle>Work Secured.</SubTitle>}
						<PrimaryButton color="orange" to={links.contactUs}>
							See Rotate
						</PrimaryButton>
						<PrimaryButton color="black" to={links.login}>
							Login
						</PrimaryButton>
					</Buttons>
				</Bottom>
				<FooterUI
					image={images?.footerUI?.childImageSharp?.gatsbyImageData}
					alt="footer UI"
				/>
				<FooterUITablet
					image={images?.footerTablet?.childImageSharp?.gatsbyImageData}
					alt="footer UI"
				/>
				<FooterUIMobileTop
					image={images?.footerTopMobile?.childImageSharp?.gatsbyImageData}
					alt="footer UI"
				/>
				<FooterUIMobileBottom
					image={images?.footerBottomMobile?.childImageSharp?.gatsbyImageData}
					alt="footer UI"
				/>
				{mobile && <SubTitle>Work Secured.</SubTitle>}
			</Content>
		</Wrapper>
	)
}

const Wrapper = styled.footer`
	display: grid;
	place-items: center;
	position: relative;

	${staticBackground};

	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background-image: url(${staticBackground});
	}
`

const Content = styled.div`
	position: relative;

	${fresponsive(css`
		width: 1440px;
		padding: 79px 242px 47px 138px;
	`)}

	${ftablet(css`
		width: 1024px;
		padding: 80px 103px 90px 106px;
	`)}

	${fmobile(css`
		width: 375px;
		padding: 160px 14px 60px 24px;
	`)}
`

const Kicker = styled.div`
	display: flex;
	flex-direction: column;
	${fresponsive(css`
		width: 155px;
		gap: 12px;
	`)}

	${fmobile(css`
		width: 171px;
	`)}
`

const Text = styled.p`
	${textStyles.body2S};
	color: ${colors.gray700};
`

const Logo = styled(LogoSVG)`
	width: 100px;
	height: 100px;

	${fresponsive(css`
		width: 144px;
		height: 29.83px;
	`)}

	${fmobile(css`
		width: 157.3px;
		height: 29px;
	`)}
`

const Top = styled.div`
	display: flex;
	position: relative;

	${fresponsive(css`
		gap: 181px;
		margin-bottom: 105px;
	`)}

	${ftablet(css`
		margin-bottom: 132px;
	`)}

	${fmobile(css`
		flex-direction: column;
		margin-bottom: 60px;
		gap: 60px;
	`)}
`

const Bottom = styled.div`
	display: flex;
	position: relative;
	z-index: 1;
	justify-content: space-between;
	align-items: center;
	${fresponsive(css`
		width: 1007px;
		margin-left: -32px;
	`)}
	${ftablet(css`
		width: 562px;
		height: 126px;
		margin-left: -10px;
		align-items: flex-start;
	`)}

	${fmobile(css`
		display: none;
	`)}
`

const Image = styled(UniversalImage)`
	position: relative;
	${fresponsive(css`
		width: 80px;
		height: 80px;
	`)}
	${fmobile(css`
		position: absolute;
		top: 160px;
		right: 14px;
	`)}
`

const Buttons = styled.div`
	display: flex;
	${fresponsive(css`
		gap: 12px;
		height: 36px;
	`)}
	${ftablet(css`
		flex-wrap: wrap;
		width: 235px;
	`)}

	${fmobile(css`
		flex-wrap: wrap;
		margin-top: 60px;
	`)}
`

const SubTitle = styled.h2`
	color: ${colors.gray700};
	position: relative;
	z-index: 1;
	${fresponsive(css`
		${textStyles.h6};
	`)}

	${ftablet(css`
		margin-bottom: 40px;
	`)}
`

const FooterUI = styled(UniversalImage)`
	position: absolute;
	bottom: 0;
	z-index: 0;
	pointer-events: none;
	display: block;

	${fresponsive(css`
		width: 1189px;
		height: 180px;
		right: -74px;
	`)}
	${ftablet(css`
		display: none;
	`)}

	${fmobile(css`
		display: none;
	`)}
`

const FooterUITablet = styled(UniversalImage)`
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 0;
	pointer-events: none;
	display: none;
	${ftablet(css`
		display: block;
		width: 753px;
		height: 291px;
	`)}
`

const FooterUIMobileTop = styled(UniversalImage)`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 0;
	pointer-events: none;
	display: none;
	${fmobile(css`
		display: block;
		width: 375px;
		height: 687px;
	`)}
`

const FooterUIMobileBottom = styled(UniversalImage)`
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 0;
	pointer-events: none;
	display: none;
	${fmobile(css`
		display: block;
		width: 375px;
		height: 124px;
	`)}
`

const LinkedIn = styled(LinkedInSVG)`
	${fresponsive(css`
		display: block;
		width: 24px;
		height: 24px;
	`)}
`

const Column = styled.div`
	display: flex;
	flex-direction: column;

	${fresponsive(css`
		gap: 18px;
	`)}

	${ftablet(css`
		gap: 18px;

		&:nth-of-type(2) {
			order: 3;
			margin-bottom: 0;
		}

		&:nth-of-type(3) {
			order: 4;
		}

		&:nth-of-type(4) {
			order: 2;
			margin-bottom: 0;
		}

		&:nth-of-type(5) {
			order: 5;
		}
	`)}
`

const SectionTitle = styled.h2`
	${textStyles.sub2};
	color: ${colors.blueWhite};

	${fresponsive(css`
		margin-left: 6px;
		margin-bottom: 6px;
	`)}
`

const Links = styled.div`
	display: flex;
	flex-flow: column wrap;

	${fresponsive(css`
		width: 724px;
		height: 301px;
		gap: 35px;
	`)}

	${ftablet(css`
		width: 488px;
		height: 502px;
		gap: 36px;
	`)}

	${fmobile(css`
		width: 100%;
		gap: 40px;
		height: auto;
		flex-wrap: nowrap;
	`)}
`

const Social = styled(UniversalLink)`
	${fresponsive(css`
		margin-left: -4px;
		width: 24px;
		height: 24px;
	`)}

	svg {
		width: 100%;
		height: 100%;
	}

	path {
		transition: fill 0.3s ease-in-out;
		fill: ${colors.gray700};
	}

	&:hover path {
		fill: ${colors.blueWhite};
	}
`
