import { ReactComponent as Arrow } from "images/global/buttonArrow.svg"
import { ReactComponent as Chevron } from "images/global/chevron.svg"
import UniversalLink, {
	type UniversalLinkProps,
} from "library/Loader/UniversalLink"
import { eases } from "library/eases"
import { fresponsive } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

type IconType = "arrow" | "chevron"

export function LinkText({
	children,
	icon,
	large,
	disabled = false,
	className = "",
	...props
}: UniversalLinkProps & {
	icon?: IconType
	large?: boolean
	className?: string
	disabled?: boolean
}) {
	const iconEl =
		icon === "arrow" ? <Arrow /> : icon === "chevron" ? <Chevron /> : null
	const canRotate = icon === "chevron"

	return (
		<Wrapper
			className={className}
			$canRotate={canRotate}
			$large={large}
			$disabled={disabled}
			{...props}
		>
			<p>{children}</p> {iconEl}
		</Wrapper>
	)
}

const Wrapper = styled(UniversalLink)<{
	$large?: boolean
	$canRotate: boolean
	$disabled: boolean
}>`
	width: fit-content;
	position: relative;
	overflow: clip;
	color: ${({ $disabled }) => ($disabled ? colors.gray700 : colors.gray500)};
	background-color: #fff0;
	transition: background-color 0.3s ${eases.quad.inOut};
	pointer-events: ${(props) => (props.$disabled ? "none" : "auto")};

	p {
		${({ $large }) => ($large ? textStyles.kicker2 : textStyles.kicker3)};
	}

	${fresponsive(css`
		display: flex;
		align-items: center;
		gap: 10px;
		border-radius: 38px;
		padding: 4px 8px;
		max-height: 18px;

		svg {
			width: 16px;
			height: 16px;
			transition: transform 0.3s ${eases.quad.inOut};
		}
	`)}

	&:hover {
		background-color: #ffffff20;

		svg {
			transition: transform 0.3s ${eases.quad.inOut};
			transform: ${({ $canRotate }) => ($canRotate ? "rotate(180deg)" : "")};
		}
	}
`
