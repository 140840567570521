import jsonFile from "data/lottie.json"
import { fresponsive } from "library/fullyResponsive"
import Lottie from "lottie-react"
import { useCallback } from "react"
import styled, { css } from "styled-components"

export default function LottieLogo({
	setShowElement,
}: {
	setShowElement?: (value: boolean) => void
}) {
	const handleAnimationLoad = useCallback(() => {
		if (!setShowElement) return
		setShowElement(true)
	}, [setShowElement])

	return (
		<Wrapper>
			<Lottie animationData={jsonFile} onDOMLoaded={handleAnimationLoad} />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	overflow: clip;
	position: absolute;
	${fresponsive(css`
		width: 88px;
		height: 88px;
		border-radius: 100%;
	`)}
`
