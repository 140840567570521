import { LinkText } from "components/Buttons/LinkText"
import { GradientBorderWrapper } from "components/GradientBorderWrapper"
import { ReactComponent as LogoSVG } from "images/global/logo.svg"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useMedia from "library/useMedia"
import styled, { css } from "styled-components"
import { staticLinearBackground } from "styles/colors"
import links from "utils/links"

export function LoginWrapper({ children }: { children?: React.ReactNode }) {
	const responsive = useMedia(false, false, true, true)

	return (
		<GradientBorderWrapper gradientBorderBrightness={40}>
			<Wrapper className="login-wrapper">
				<LogoWrapper ariaLabel="to homepage" to={links.home}>
					<Logo />
				</LogoWrapper>

				{!responsive && <LinkText to={links.login}>Login</LinkText>}
				{responsive && children && children}
			</Wrapper>
		</GradientBorderWrapper>
	)
}

const Wrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	${staticLinearBackground};

	${fresponsive(css`
		width: 244px;
		border-radius: 81px;
		padding: 14px 20px;
	`)}

	${ftablet(css`
		align-items: center;
	`)}

	${fmobile(css`
		padding: 6px 24px;
		height: 60px;
		align-items: center;
	`)}
`

const Logo = styled(LogoSVG)``

const LogoWrapper = styled(UniversalLink)`
	position: relative;
	font-family: none;

	${fresponsive(css`
		width: 72px;
	`)}

	${ftablet(css`
		width: 96px;
		height: 20px;
	`)}

${fmobile(css`
		width: 96px;
		display: none;
	`)}
`
