import buttonPattern from "images/global/buttonPattern.png"
import UniversalLink, {
	type UniversalLinkProps,
} from "library/Loader/UniversalLink"
import { eases } from "library/eases"
import { fresponsive, ftablet } from "library/fullyResponsive"
import { forwardRef } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export type ColorType = "orange" | "teal" | "black" | "lightblue"

const colorLibrary = {
	orange: colors.js.orange500,
	teal: colors.js.teal,
	black: "#2B2B2B60",
	lightblue: colors.js.neonTeal,
}

function PrimaryButtonRaw({
	children,
	color,
	...props
}: UniversalLinkProps & {
	color: ColorType
}) {
	const textColor =
		color === "black" || color === "teal" ? colors.pureWhite : colors.blackPure

	const color500 = colorLibrary[color] + 42
	const color800 = colorLibrary[color] + 80

	return (
		<Wrapper
			$shadow1={color500}
			$shadow2={color800}
			$color={colorLibrary[color]}
			$textColor={textColor}
			{...props}
		>
			<Inner>
				<p>{children}</p>
			</Inner>
		</Wrapper>
	)
}

export const PrimaryButton = forwardRef(
	(
		props: UniversalLinkProps & {
			color: ColorType
		},
		ref,
	) => (
		// @ts-expect-error types of UniversalLink are wrong and it's not worth fixing before react 19 is out
		<PrimaryButtonRaw {...props} forwardRef={ref} />
	),
)

const Inner = styled.div`
	width: 100%;
	height: 100%;

	${fresponsive(css`
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 81px;
		padding: 14px 16px;
		max-height: 36px;
	`)}
	p {
		z-index: 1;
		${textStyles.sub3};
	}

	&::before {
		position: absolute;
		content: "";
		opacity: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url(${buttonPattern});
		${fresponsive(css`
			background-size: 92px 36px;
		`)}
	}

	${ftablet(css`
		padding: 18px 20px;

		p {
			${textStyles.sub2};
		}
	`)}
`

const Wrapper = styled(UniversalLink)<{
	$color: string
	$textColor: string
	$shadow1: string
	$shadow2: string
}>`
	width: fit-content;
	height: fit-content;
	position: relative;
	background: ${({ $color }) => $color};
	overflow: clip;
	color: ${({ $textColor }) => $textColor};
	transition: all 0.3s ${eases.quad.inOut};
	${fresponsive(css`
		display: flex;
		align-items: center;
		border-radius: 81px;
		${textStyles.sub3};
	`)}

	${ftablet(css`
		height: 47px;
	`)}

	&::before {
		width: 150%;
		left: -25%;
		height: 24px;
		z-index: 0;
		position: absolute;
		content: "";
		border-radius: 50%;
		opacity: 0;
		scale: 0.5;
		transition: all 0.3s ${eases.quad.inOut};
		background: linear-gradient(
			0deg,
			rgba(255 255 255 / 16%) 0%,
			rgba(255 255 255 / 0%) 88%
		);

		${fresponsive(css`
			border-radius: 40%;
			filter: blur(2px);
			top: -5px;
		`)}
	}

	&:hover {
		&::before {
			opacity: 1;
			scale: 1;
		}

		box-shadow:
			0 2px 13.8px 0 ${({ $shadow1 }) => $shadow1},
			0 1px 0.5px 0 ${({ $shadow2 }) => $shadow2},
			0 -1px 0.5px 0 #0000001f,
			0 2px 4px 0 #13090014;
	}
`
