import Footer from "components/Footer"
import { useBackButton } from "library/Loader/TransitionUtils"
import Scroll from "library/Scroll"
import { useTrackPageReady } from "library/pageReady"
import useTrackFrameTime from "library/useTrackFrameTime"
import styled, { createGlobalStyle, css } from "styled-components"
import colors, { ColorStyle } from "styles/colors"
import CookieConsent from "./CookieConsent"
import Header from "./Header"
import Preloader from "./Preloader"
import Transition from "./Transition"

interface LayoutProps {
	children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
	useTrackPageReady()
	useBackButton()
	useTrackFrameTime()

	return (
		<>
			<CookieConsent />
			<GlobalStyle />
			<ColorStyle />
			<Preloader />
			<Transition />
			<Header />
			<Scroll>
				<Main>{children}</Main>
				<Footer />
			</Scroll>
		</>
	)
}

const Main = styled.main`
	overflow-x: clip;
`

const globalCss = css`
	/* default text styles */
	html {
		/* if your project uses a dark color for most text, set that here */
		color: ${colors.blackMain};
		background: ${colors.backgroundBlack};
	}

	#smooth-content {
		background: ${colors.backgroundBlack};
	}

	* {
		/* need this so that fonts match figma */
		text-rendering: geometricprecision;
		-webkit-font-smoothing: antialiased;
	}

	/* dev utilities */
	/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
	html:has(gatsby-qod) {
		* {
			font-family: cursive;
		}
	}

	/** restore default focus states for elements that need them */
	*:focus-visible {
		outline: 2px solid #00f8;
	}
`

const GlobalStyle = createGlobalStyle`${globalCss}`
