import { fresponsive } from "library/fullyResponsive"
import styled, { css } from "styled-components"

import gsap from "gsap"
import useAnimation from "library/useAnimation"
import { useRef } from "react"

export function GradientBorderWrapper({
	children,
	borderRadius = "99vw",
	gradientBorderBrightness = 100,
}: {
	children: React.ReactNode
	borderRadius?: string
	gradientBorderBrightness?: number
}) {
	const background = useRef<HTMLDivElement | null>(null)

	useAnimation(() => {
		if (!background.current) return

		// Not sure why but without setting this the border isn't showing up unless it is hovered.

		gsap.set(background.current, {
			backgroundImage: `linear-gradient(
			180deg,
			rgba(255 255 255 / ${gradientBorderBrightness}%) 0%,
			rgba(255 255 255 / 0%) 50%`,
		})
	}, [gradientBorderBrightness])

	const mouseEnter = (el: HTMLDivElement | null, brightness: number) => {
		if (!el) return

		gsap.fromTo(
			el,
			{
				backgroundImage: `linear-gradient(
                180deg,
                rgba(255 255 255 / 0.${brightness}) 0%,
                rgba(255 255 255 / 0) 50%
            )`,
			},
			{
				duration: 0.25,
				ease: "power4.inOut",
				backgroundImage: `linear-gradient(
                359deg,
                rgba(255 255 255 / 0.${brightness}) 0%,
                rgba(255 255 255 / 0) 50%
            )`,
			},
		)
	}

	const mouseLeave = (el: HTMLDivElement | null, brightness: number) => {
		if (!el) return
		gsap.to(el, {
			duration: 0.25,
			ease: "power4.inOut",
			backgroundImage: `linear-gradient(
                180deg,
                rgba(255 255 255 / 0.${brightness}) 0%,
                rgba(255 255 255 / 0%) 50%
            )`,
		})
	}

	return (
		<Wrapper
			ref={background}
			$borderRadius={borderRadius}
			$gradientBorderBrightness={gradientBorderBrightness}
			onMouseEnter={() =>
				mouseEnter(background?.current, gradientBorderBrightness)
			}
			onMouseLeave={() =>
				mouseLeave(background?.current, gradientBorderBrightness)
			}
		>
			<Inner $borderRadius={borderRadius}>{children}</Inner>
		</Wrapper>
	)
}

const Wrapper = styled.div<{
	$borderRadius: string
	$gradientBorderBrightness: number
}>`
	position: relative;

	/* overflow: clip; */
	width: fit-content;
	height: fit-content;

	${({ $borderRadius }) =>
		fresponsive(css`
			border-radius: ${$borderRadius};
		`)}
`

const Inner = styled.div<{ $borderRadius: string }>`
	position: relative;
	border: 0.5px solid transparent;
	z-index: 1;
	overflow: clip;
	${({ $borderRadius }) =>
		fresponsive(css`
			border-radius: ${$borderRadius};
		`)}
`
