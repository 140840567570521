import { type Config, defaultConfig } from "library/defaultConfig"

/**
 * The transition names that can be used in the page transition
 */
export type TransitionNames = "shrink-grow"

const config: Config = {
	...defaultConfig,
	defaultTransition: "shrink-grow",
	scrollRestoration: false,
}

export default config
